/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonAlert,
} from '@ionic/react';
import PageWrapper from '../../layout/PageWrapper';
import { ModelDeviceRelation } from '../../models/Device';
import { Redirect } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { getDeviceGenderIcon, getDeviceStatusBadge } from '../../helpers/LatLogHelpers';

import './ListPage.scss';
import { chatbubbles, footsteps, megaphone } from 'ionicons/icons';

interface Props {
  deviceRelations: ModelDeviceRelation[];
}

const ListPage: React.FC<Props> = ({ deviceRelations }) => {
  const { authInfo } = useAuth();
  const [presentAlert] = useIonAlert();

  const handleAlert = (deviceRelation: ModelDeviceRelation) => {
    presentAlert({
      header: 'Siker',
      message: `Értesítés sikeresen kiküldve neki: ${deviceRelation.name ?? ''}`,
      buttons: ['Rendben'],
    });
  };

  if (authInfo.loggedIn === false) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper title="Lista" id="page-list">
      <div className="devices-list ">
        {deviceRelations.map((deviceRelation: ModelDeviceRelation) => (
          <IonCard key={'device_' + deviceRelation.id}>
            <IonCardHeader>
              <IonCardSubtitle>Eszköz: #{deviceRelation.id}</IonCardSubtitle>
              <IonCardTitle>{deviceRelation.name ?? ''}</IonCardTitle>
              {getDeviceStatusBadge(deviceRelation.device.last_device_log)}
              <span className="gender">{getDeviceGenderIcon(deviceRelation)}</span>
            </IonCardHeader>
            <IonCardContent>
              <div className="buttons-grid">
                <IonButton fill="solid" routerLink={`device/${deviceRelation.device.id}`} routerDirection="none">
                  <IonIcon icon={footsteps} slot="icon-only" />
                </IonButton>
                <IonButton fill="solid" color="warning" onClick={() => handleAlert(deviceRelation)}>
                  <IonIcon icon={chatbubbles} slot="icon-only" />
                </IonButton>
                <IonButton fill="solid" color="danger" onClick={() => handleAlert(deviceRelation)}>
                  <IonIcon icon={megaphone} slot="icon-only" />
                </IonButton>
              </div>
            </IonCardContent>
          </IonCard>
        ))}
      </div>
      {deviceRelations.length === 0 && (
        <div className="tutorial">
          <h1>Nem található eszköz</h1>
          <p>Kérem a jobb alsó sarokban található Plusz gomb megnyomásával vegye fel az első eszközt...</p>
        </div>
      )}
    </PageWrapper>
  );
};
export default ListPage;
