import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SystemState {
  showLogoutAlert: boolean;
  loading: { show: boolean; message: string };
  toast: {
    duration: number;
    message: string;
    color: string;
  };
  geolocation: {
    lat: number;
    lng: number;
  };
}

const initialState: SystemState = {
  showLogoutAlert: false,
  loading: { show: false, message: '' },
  toast: {
    duration: 0,
    message: '',
    color: '',
  },
  geolocation: {
    lat: 0,
    lng: 0,
  },
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setShowLogoutAlert: (state, action: PayloadAction<boolean>) => {
      state.showLogoutAlert = action.payload;
    },
    setGeolocation: (state, action: PayloadAction<{ lat: number; lng: number }>) => {
      state.geolocation = action.payload;
    },
    addLoading: (state, action: PayloadAction<string>) => {
      state.loading = { show: true, message: action.payload };
    },
    removeLoading: (state) => {
      state.loading = { show: false, message: '' };
    },
    addToast: (
      state,
      action: PayloadAction<{
        duration: number;
        message: string;
        color: string;
      }>
    ) => {
      state.toast = {
        duration: action.payload.duration,
        message: action.payload.message,
        color: action.payload.message,
      };
    },
  },
});

export const { setShowLogoutAlert, setGeolocation, addLoading, removeLoading, addToast } = systemSlice.actions;
export default systemSlice.reducer;
