import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ModelDevice, ModelDeviceLog, ModelDeviceRelation } from '../models/Device';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import systemSlice, { addLoading, removeLoading } from '../store/slices/systemSlice';
import MarkerModal from './modals/MarkerModal';
import dayjs from 'dayjs';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import './Map.scss';

/*
import greenMarkerImage from '../assets/icons/green-marker.png';
import redMarkerImage from '../assets/icons/red-marker.png';
import yellowMarkerImage from '../assets/icons/yellow-marker.png';
import orangeMarkerImage from '../assets/icons/orange-marker.png';
*/

/*
const greenIcon = new L.Icon({
    iconUrl: greenMarkerImage,
    iconRetinaUrl: greenMarkerImage,
    iconSize: new L.Point(40, 50),
});
const redIcon = new L.Icon({
    iconUrl: redMarkerImage,
    iconRetinaUrl: redMarkerImage,
    iconSize: new L.Point(40, 50),
});
const orangeIcon = new L.Icon({
    iconUrl: orangeMarkerImage,
    iconRetinaUrl: orangeMarkerImage,
    iconSize: new L.Point(40, 50),
});
const yellowIcon = new L.Icon({
    iconUrl: yellowMarkerImage,
    iconRetinaUrl: yellowMarkerImage,
    iconSize: new L.Point(40, 50),
});
*/

interface Props {
  deviceRelations: ModelDeviceRelation[];
}

const Map: React.FC<Props> = ({ deviceRelations }) => {
  const geolocation = useAppSelector((state) => state.system.geolocation);
  const map = useRef<any>(null);

  const [renderMap, setRenderMap] = useState<boolean>(false);
  const [selectedDeviceRelation, setSelectedDeviceRelation] = useState<ModelDeviceRelation | undefined>(undefined);

  const getDeviceLastPositionIcon = (deviceRelation: ModelDeviceRelation): L.Icon | L.DivIcon => {
    const lastLogForDevice = deviceRelation.device.last_device_log;

    let name = deviceRelation.name;
    if (name) {
      name = name[0];
    }

    let className = '';
    if (selectedDeviceRelation && deviceRelation.id === selectedDeviceRelation.id) {
      className = 'selected';
    }

    if (lastLogForDevice) {
      if (dayjs(lastLogForDevice.created_at).isAfter(dayjs().add(-10, 'minutes'))) {
        // Green
        return new L.DivIcon({
          html: "<div class='custom-marker green " + className + "'>" + name + '</div>',
          iconSize: new L.Point(50, 50),
        });
      } else if (dayjs(lastLogForDevice.created_at).isAfter(dayjs().add(-60, 'minutes'))) {
        // Yellow
        return new L.DivIcon({
          html: "<div class='custom-marker yellow " + className + "'>" + name + '</div>',
          iconSize: new L.Point(50, 50),
        });
      } else {
        // Orange
        return new L.DivIcon({
          html: "<div class='custom-marker orange " + className + "'>" + name + '</div>',
          iconSize: new L.Point(50, 50),
        });
      }
    } else {
      // Red
      return new L.DivIcon({
        html: "<div class='custom-marker red " + className + "'>" + name + '</div>',
        iconSize: new L.Point(50, 50),
      });
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => setRenderMap(true), 10);

    return () => {
      setRenderMap(false);
    };
  }, []);

  return (
    <>
      {renderMap && (
        <>
          <div id="map">
            {/* geolocation.lat, geolocation.lng */}
            <MapContainer center={[47.13087664687684, 17.628692245765926]} zoom={17} scrollWheelZoom={false} ref={map}>
              <TileLayer attribution="" url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
              {deviceRelations
                .filter((deviceRelation: ModelDeviceRelation) => deviceRelation.device.last_device_log)
                .map((deviceRelation: ModelDeviceRelation) => (
                  <Marker
                    key={'device_' + deviceRelation.id}
                    position={{
                      lat: deviceRelation.device.last_device_log?.lat ?? 0,
                      lng: deviceRelation.device.last_device_log?.lng ?? 0,
                    }}
                    icon={getDeviceLastPositionIcon(deviceRelation)}
                    eventHandlers={{
                      click: (e) => {
                        if (map && map.current) {
                          /*const latLng = L.latLng([
                                                        deviceRelation.device.device_logs[0].lat,
                                                        deviceRelation.device.device_logs[0].lng,
                                                    ]);
                                                    const point = map.current.latLngToContainerPoint(latLng);
                                                    const newPoint = L.point([point.x, point.y + map.current.getSize().y / 3 + 20]);
                                                    const newLatLng = map.current.containerPointToLatLng(newPoint);
                                                    map.current.setView(newLatLng, map.current.getZoom(), {
                                                        animate: true,
                                                    });*/
                          const latLng = L.latLng([
                            deviceRelation.device.last_device_log?.lat ?? 0,
                            deviceRelation.device.last_device_log?.lng ?? 0,
                          ]);
                          map.current.setView(latLng, map.current.getZoom(), {
                            animate: true,
                          });
                        }
                        setSelectedDeviceRelation(deviceRelation);
                      },
                    }}
                  />
                ))}
            </MapContainer>
          </div>
          <MarkerModal
            handleClose={() => {
              if (map && map.current) {
                if (selectedDeviceRelation) {
                  map.current.setView(
                    [
                      selectedDeviceRelation.device.last_device_log?.lat ?? 0,
                      selectedDeviceRelation.device.last_device_log?.lng ?? 0,
                    ],
                    map.current.getZoom(),
                    {
                      animate: true,
                    }
                  );
                }
              }
              setSelectedDeviceRelation(undefined);
            }}
            show={selectedDeviceRelation !== undefined}
            deviceRelation={selectedDeviceRelation}
          />
        </>
      )}
    </>
  );
};

export default Map;
