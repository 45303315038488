import { HttpClient } from '../helpers/HttpClient';
import { Capacitor } from '@capacitor/core';
import { Device } from '@ionic-native/device';

export const postLogin = async () => {
  return await HttpClient({
    url: 'login',
    method: 'post',
    data: {
      imei: Capacitor.isNativePlatform() ? Device.uuid : '51b8c845-af9a-4a85-86e9-af026edad29d',
    },
  });
};

export const getUser = async () => {
  return await HttpClient({
    url: 'user',
    method: 'get',
  });
};
