import { IonLoading } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const GlobalLoading: React.FC = () => {
  const loadingFromReducer = useSelector((state: RootState) => state.system.loading);
  const [loading, setLoading] = useState<{ show: boolean; message: string }>({
    show: loadingFromReducer.show,
    message: loadingFromReducer.message ?? '',
  });

  useEffect(() => {
    if (!loadingFromReducer.show) {
      setTimeout(() => {
        setLoading(loadingFromReducer);
      }, 500);
    } else {
      setLoading(loadingFromReducer);
    }
  }, [loadingFromReducer]);

  return <IonLoading cssClass="custom-loading" isOpen={loading.show} message={loading.message} />;
};

export default GlobalLoading;
