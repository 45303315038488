import React from 'react';
import Axios from 'axios';
import { useIonToast } from '@ionic/react';

let registered: boolean = false;
const GlobalErrors: React.FC = () => {
  const [presentToast] = useIonToast();

  if (!registered) {
    Axios.interceptors.response.use(
      (response) => response,
      (err) => {
        const message =
          err && err.response && err.response.data && err.response.data.message ? err.response.data.message : null;

        if (message) {
          presentToast({
            message: message,
            duration: 4000,
            color: 'danger',
            buttons: [
              {
                text: 'Rendben',
                role: 'cancel',
              },
            ],
          });
        }

        throw err;
      }
    );
    registered = true;
  }

  return <></>;
};

export default GlobalErrors;
