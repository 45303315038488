import Axios from 'axios';
import { ConstantAuthToken } from '../constants/LocalStorageConstants';
import LocalStorage from './LocalStorage';

const qs = require('qs');

const API_URL = process.env.REACT_APP_URL + '/api';
const AUTH_HEADER = 'Authorization';

export const HttpClient = ({
  method = 'get',
  url = '',
  data = {},
  params = {},
  timeout = 15000,
  additionalHeaders = {},
  contentType = 'application/json',
  cancelToken = undefined,
}: any) => {
  let headers = {
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    Accept: 'application/json, text/plain',
    ...additionalHeaders,
  };

  let token = LocalStorage.get(ConstantAuthToken);
  if (token) {
    headers[AUTH_HEADER] = `Bearer ${token}`;
  }

  if (contentType === 'multipart/form-data') {
    let oldData = data;
    data = new FormData();
    for (var key in oldData) {
      data.append(key, oldData[key]);
    }
  } else if (contentType === 'application/x-www-form-urlencoded') {
    data = qs.stringify(data);
  }

  return Axios({
    url: `${API_URL}/${url}`,
    method,
    timeout,
    headers,
    data,
    params,
    cancelToken,
  });
};

export default HttpClient;
