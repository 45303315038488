import React from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { ModelDevice, ModelDeviceLog, ModelDeviceRelation } from '../../models/Device';
import { getDeviceGenderIcon, getDeviceStatusBadge } from '../../helpers/LatLogHelpers';

import './MarkerModal.scss';
import { chatbubbles, footsteps, megaphone } from 'ionicons/icons';

interface Props {
  show: boolean;
  handleClose: () => void;
  deviceRelation?: ModelDeviceRelation;
}

const MarkerModal: React.FC<Props> = ({ show, handleClose, deviceRelation }) => {
  const [presentAlert] = useIonAlert();

  const handleAlert = (deviceRelation: ModelDeviceRelation) => {
    presentAlert({
      header: 'Siker',
      message: `Értesítés sikeresen kiküldve neki: ${deviceRelation.name ?? ''}`,
      buttons: ['Rendben'],
    });
  };

  return (
    <IonModal isOpen={show} onDidDismiss={handleClose} className="marker-modal" initialBreakpoint={0.25} breakpoints={[0, 0.25]}>
      {deviceRelation && (
        <IonContent className="ion-padding">
          <div className="modal-title">{deviceRelation.name ?? ''}</div>
          {getDeviceStatusBadge(deviceRelation.device.last_device_log)}
          <span className="gender">{getDeviceGenderIcon(deviceRelation)}</span>
          <div className="buttons-grid">
            <IonButton
              fill="solid"
              routerLink={`device/${deviceRelation.device.id}`}
              routerDirection="none"
              onClick={handleClose}
            >
              <IonIcon icon={footsteps} slot="icon-only" />
            </IonButton>
            <IonButton fill="solid" color="warning" onClick={() => handleAlert(deviceRelation)}>
              <IonIcon icon={chatbubbles} slot="icon-only" />
            </IonButton>
            <IonButton fill="solid" color="danger" onClick={() => handleAlert(deviceRelation)}>
              <IonIcon icon={megaphone} slot="icon-only" />
            </IonButton>
          </div>
        </IonContent>
      )}
    </IonModal>
  );
};

export default MarkerModal;
