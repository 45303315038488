/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { IonFab, IonFabButton, IonIcon, useIonLoading } from '@ionic/react';
import PageWrapper from '../../layout/PageWrapper';
import { ModelDeviceRelation } from '../../models/Device';
import Map from '../../components/Map';
import { add } from 'ionicons/icons';
import { Redirect } from 'react-router';
import { useAuth } from '../../context/AuthContext';

// import {
//     handleApiError,
//     handleApiResponseError,
// } from "../../helpers/GlobalHelpers";
// import { getDevices } from "../../services/DeviceServices";

import './MapPage.scss';

interface Props {
  deviceRelations: ModelDeviceRelation[];
}

const MapPage: React.FC<Props> = ({ deviceRelations }) => {
  const { authInfo } = useAuth();

  if (authInfo.loggedIn === false) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper title="Térkép" id="page-map">
      {deviceRelations.length === 0 && (
        <div className="tutorial">
          <h1>Nem található eszköz</h1>
          <p>Kérem a jobb alsó sarokban található Plusz gomb megnyomásával vegye fel az első eszközt...</p>
        </div>
      )}
      <Map deviceRelations={deviceRelations} />
    </PageWrapper>
  );
};
export default MapPage;
