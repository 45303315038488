import React, { useState } from 'react';
import { IonButton, IonContent, useIonLoading, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import PageWrapper from '../layout/PageWrapper';
import { hideTabs, showTabs } from '../helpers/GlobalHelpers';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper';
import { useAuth } from '../context/AuthContext';
import { Redirect } from 'react-router';
import { postLogin } from '../services/AuthServices';

import 'swiper/scss';
import '@ionic/react/css/ionic-swiper.css';
import './IntroPage.scss';

interface Props {
  onLogin: (login: boolean, token: string) => void;
}

const IntroPage: React.FC<Props> = ({ onLogin }) => {
  const { authInfo } = useAuth();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const handleLogin = async () => {
    presentLoading('Kérem várjon...');
    postLogin()
      .then((res: any) => {
        dismissLoading();
        onLogin(true, res.data.plainTextToken);
      })
      .catch((err: any) => {
        dismissLoading();
      });
  };

  useIonViewWillEnter(() => {
    hideTabs();
  });

  useIonViewWillLeave(() => {
    showTabs();
  });

  if (authInfo.loggedIn) {
    if (authInfo.userInfo && authInfo.userInfo.email_verified_at != null) {
      return <Redirect to="/map" />;
    } else {
      return <Redirect to="/verify" />;
    }
  }

  return (
    <PageWrapper title="Bevezető" hideHeader>
      <IonContent>
        <Swiper onSwiper={(swiper) => setSwiperInstance(swiper)} className="intro-slides">
          <SwiperSlide className="intro-slide intro-slide-1">
            <div className="details">
              <h1>Első oldal</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at urna est. Praesent risus enim,
                dignissim nec ultricies at, sagittis at lorem. Mauris purus augue, tincidunt et vulputate ac, laoreet
                nec justo. Morbi non arcu hendrerit, tincidunt nunc ac, dictum urna. Cras pharetra orci nec dolor mattis
                efficitur.
              </p>
              <IonButton expand="block" onClick={() => swiperInstance?.slideNext()}>
                Következő
              </IonButton>
            </div>
          </SwiperSlide>
          <SwiperSlide className="intro-slide intro-slide-2">
            <div className="details">
              <h1>Második oldal</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at urna est. Praesent risus enim,
                dignissim nec ultricies at, sagittis at lorem. Mauris purus augue, tincidunt et vulputate ac, laoreet
                nec justo. Morbi non arcu hendrerit, tincidunt nunc ac, dictum urna. Cras pharetra orci nec dolor mattis
                efficitur.
              </p>
              <IonButton expand="block" onClick={() => swiperInstance?.slideNext()}>
                Következő
              </IonButton>
            </div>
          </SwiperSlide>
          <SwiperSlide className="intro-slide intro-slide-3">
            <div className="details">
              <h1>Harmadik oldal</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at urna est. Praesent risus enim,
                dignissim nec ultricies at, sagittis at lorem. Mauris purus augue, tincidunt et vulputate ac, laoreet
                nec justo. Morbi non arcu hendrerit, tincidunt nunc ac, dictum urna. Cras pharetra orci nec dolor mattis
                efficitur.
              </p>
              <IonButton expand="block" onClick={handleLogin}>
                Kezdjük
              </IonButton>
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </PageWrapper>
  );
};

export default IntroPage;
