import React, { Dispatch, SetStateAction, useContext } from 'react';

export interface Auth {
  authInfo: {
    loggedIn: boolean;
    userInfo: any;
    token: string | null;
  };
  setAuthInfo: Dispatch<SetStateAction<any>> | null;
  setAuth: (login: boolean, token: string | null, needPin: boolean | null) => void;
}

export const AuthContext = React.createContext<Auth>({
  authInfo: {
    userInfo: null,
    loggedIn: false,
    token: null,
  },
  setAuthInfo: null,
  setAuth: (login: boolean, token: string | null) => {},
});

export function useAuth(): Auth {
  return useContext(AuthContext);
}
