/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PageWrapper from '../../layout/PageWrapper';
import { useHistory, useParams } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { postLogin } from '../../services/AuthServices';
import { postDeviceRelation } from '../../services/DeviceServices';
import { addLoading, removeLoading } from '../../store/slices/systemSlice';
import { useAppDispatch } from '../../store/hooks';
import { useIonToast } from '@ionic/react';

interface Props {
  onLogin: (login: boolean, token: string) => void;
}

const AssignPage: React.FC<Props> = ({ onLogin }) => {
  const dispatch = useAppDispatch();
  const [presentToast] = useIonToast();
  const { authInfo } = useAuth();
  const { serial }: { serial: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!authInfo?.token) {
      dispatch(addLoading('Belépés folyamatban...'));
      postLogin()
        .then((res: any) => {
          onLogin(true, res.data.plainTextToken);
        })
        .catch((err) => {
          dispatch(removeLoading());
        });
    }
  }, []);

  useEffect(() => {
    if (authInfo?.token) {
      dispatch(addLoading('Hozzárendelés folyamatban...'));
      postDeviceRelation(serial ?? '', '', 'otter')
        .then((res: any) => {
          // history.replace('/map');
          presentToast({
            message: 'Sikeres hozzárendelés, továbbítjuk a térképre...',
            duration: 3000,
            color: 'primary',
          });
        })
        .finally(() => {
          dispatch(removeLoading());
          setTimeout(() => {
            history.replace('/map');
          }, 3000);
        });
    }
  }, [authInfo]);

  return (
    <PageWrapper title="Eszköz hozzárendelés" id="page-assign">
      <></>
    </PageWrapper>
  );
};
export default AssignPage;
