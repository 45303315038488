import { IonBadge, IonIcon } from '@ionic/react';
import dayjs from 'dayjs';
import { female, male, transgender } from 'ionicons/icons';
import { ModelDeviceLog, ModelDeviceRelation } from '../models/Device';

export const getDeviceStatusBadge = (log?: ModelDeviceLog) => {
  if (log) {
    if (dayjs(log.created_at).isAfter(dayjs().add(-10, 'minutes'))) {
      // Green
      return <IonBadge color="success">Utolsó frissítés: {dayjs(log.created_at).format('HH:mm')}</IonBadge>;
    } else if (dayjs(log.created_at).isAfter(dayjs().add(-60, 'minutes'))) {
      // Yellow
      return <IonBadge color="warning">Utolsó frissítés: {dayjs(log.created_at).format('HH:mm')}</IonBadge>;
    } else {
      // Orange
      return <IonBadge color="danger">Utolsó frissítés: {dayjs(log.created_at).format('HH:mm')}</IonBadge>;
    }
  } else {
    // Red
    return <IonBadge color="danger">HIBA</IonBadge>;
  }
};

export const getDeviceGenderIcon = (deviceRelation: ModelDeviceRelation) => {
  switch (deviceRelation.gender) {
    case 'male':
      return <IonIcon icon={male} />;
    case 'female':
      return <IonIcon icon={female} />;
    default:
      return <IonIcon icon={transgender} />;
  }
};
