import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ModelDeviceLog } from '../models/Device';
import { MapContainer, Marker, Polyline, TileLayer } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import { useAppSelector } from '../store/hooks';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonModal,
  IonRange,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import dayjs from 'dayjs';
import { RangeValue } from '@ionic/core';

import 'leaflet/dist/leaflet.css';
import './MapHistory.scss';
import { batteryFull, location, time, chevronDownOutline, chevronUpOutline, analyticsOutline } from 'ionicons/icons';

interface Props {
  deviceId: string;
  logs: ModelDeviceLog[];
  loading: boolean;
}

const markerIcon = new L.DivIcon({
  html: "<div class='custom-marker log-marker'></div>",
  iconSize: new L.Point(15, 15),
});

const markerIconSelected = new L.DivIcon({
  html: "<div class='custom-marker log-marker selected'></div>",
  iconSize: new L.Point(25, 25),
});

const MapHistory: React.FC<Props> = ({ deviceId, logs, loading }) => {
  const geolocation = useAppSelector((state) => state.system.geolocation);
  const map = useRef<any>(null);
  const [timelineOpen, setTimelineOpen] = useState<boolean>(false);

  const [renderMap, setRenderMap] = useState<boolean>(false);
  const [lastEmittedValue, setLastEmittedValue] = useState<RangeValue>(0);

  const polyline: LatLngExpression[] = logs.map((log: ModelDeviceLog) => {
    return [log.lat, log.lng];
  });

  useLayoutEffect(() => {
    setTimeout(() => setRenderMap(true), 10);

    return () => {
      setRenderMap(false);
    };
  }, []);

  useEffect(() => {
    setLastEmittedValue(Math.max(logs.length - 1, 0));
  }, [logs]);

  useEffect(() => {
    if (logs.length > 0 && logs[lastEmittedValue as number] && map && map.current) {
      const latLng = L.latLng([logs[lastEmittedValue as number].lat ?? 0, logs[lastEmittedValue as number].lng ?? 0]);
      map.current.setView(latLng, 18, {
        animate: true,
      });
    }
  }, [lastEmittedValue]);

  /*
  useEffect(() => {
    if (logs.length > 0 && logs[0] && map && map.current) {
      const latLng = L.latLng([logs[0].lat ?? 0, logs[0].lng ?? 0]);
      map.current.setView(latLng, map.current.getZoom(), {
        animate: true,
      });
    }
  }, [logs]);
  */

  return (
    <>
      {renderMap && (
        <div id="map_history" className={timelineOpen ? ' timeline-opened' : ''}>
          <MapContainer center={[geolocation.lat, geolocation.lng]} zoom={17} scrollWheelZoom={false} ref={map}>
            <TileLayer attribution="" url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
            {logs.length > 0 && (
              <>
                <Polyline pathOptions={{ color: '#105157', weight: 4 }} positions={polyline} />
                {logs.map((log: ModelDeviceLog, index: number) => (
                  <Marker
                    key={'log_' + log.id}
                    position={{
                      lat: log.lat ?? 0,
                      lng: log.lng ?? 0,
                    }}
                    icon={log.id === (logs[lastEmittedValue as number]?.id ?? -1) ? markerIconSelected : markerIcon}
                    eventHandlers={{
                      click: (e) => {
                        setLastEmittedValue(index);
                      },
                    }}
                  />
                ))}
              </>
            )}
          </MapContainer>
          <IonFab vertical="bottom" horizontal="center" slot="fixed">
            <IonFabButton color="primary" onClick={() => setTimelineOpen((prev) => !prev)}>
              <IonIcon icon={analyticsOutline} />
            </IonFabButton>
          </IonFab>
          <IonModal
            isOpen={timelineOpen}
            trigger="open-modal"
            initialBreakpoint={0.6}
            breakpoints={[0, 0.4, 0.6, 1]}
            onDidDismiss={() => setTimelineOpen(false)}
          >
            <IonContent className="timeline ion-padding">
              <h3>Idővonal</h3>
              {logs.length > 0 ? (
                <>
                  {logs.length > 1 && (
                    <IonRange
                      pin={true}
                      onIonChange={({ detail }) => setLastEmittedValue(detail.value)}
                      pinFormatter={(value: number) =>
                        logs[value]?.created_at ? dayjs(logs[value].created_at).format('HH:mm') : ''
                      }
                      ticks={true}
                      snaps={true}
                      min={0}
                      max={logs.length - 1}
                      value={lastEmittedValue}
                    />
                  )}
                  {logs[lastEmittedValue as number] && (
                    <IonGrid className="log-details">
                      <IonRow>
                        <IonCol>
                          <IonCard>
                            <IonCardContent className="time">
                              <IonIcon icon={time} />
                              <span>{dayjs(logs[lastEmittedValue as number].created_at).format('HH:mm')}</span>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                        <IonCol>
                          <IonCard>
                            <IonCardContent className="battery">
                              <IonIcon icon={batteryFull} />
                              <span>{logs[lastEmittedValue as number].battery_level ?? ' - '} %</span>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonCard>
                            <IonCardContent className="location">
                              <IonIcon icon={location} />
                              <span>{logs[lastEmittedValue as number].address ?? ' - '}</span>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <>
                      <IonSkeletonText animated={true} style={{ width: '60%' }} />
                      <IonSkeletonText animated={true} style={{ width: '80%' }} />
                      <IonSkeletonText animated={true} style={{ width: '80%' }} />
                      <IonSkeletonText animated={true} style={{ width: '30%' }} />
                    </>
                  ) : (
                    <p>Nem található előzmény...</p>
                  )}
                </>
              )}
            </IonContent>
          </IonModal>
        </div>
      )}
    </>
  );
};

export default MapHistory;
