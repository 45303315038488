import { useState } from 'react';
import { IonApp, setupIonicReact } from '@ionic/react';
import { AuthContext } from './context/AuthContext';
import Routes from './layout/Routes';
import LocalStorage from './helpers/LocalStorage';
import { ConstantAuthToken } from './constants/LocalStorageConstants';
import GlobalErrors from './components/global/GlobalErrors';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// import "./theme/Global.scss";
import './App.scss';

setupIonicReact();

const App: React.FC = () => {
  // const [addToast] = useIonToast();
  const [authInfo, setAuthInfo] = useState<{
    loggedIn: boolean;
    token: string | null;
    userInfo: any;
  }>({
    loggedIn: Boolean(localStorage.getItem(ConstantAuthToken)),
    token: localStorage.getItem(ConstantAuthToken),
    userInfo: null,
  });

  const setAuth = async (login: boolean, token: string | null) => {
    if (LocalStorage.get(ConstantAuthToken) !== token) {
      LocalStorage.set(ConstantAuthToken, token);
    }

    if (login) {
      handleRefreshUserData(login, token);
    } else {
      setAuthInfo((prevState: any) => ({
        ...prevState,
        loggedIn: login,
        token: token,
        userInfo: null,
      }));
    }
  };

  const handleRefreshUserData = (login: boolean, token: string | null) => {
    // TODO lekérjük a usert
    // getUser()
    //     .then((res: any) => {
    //         if (
    //             handleApiResponseError(
    //                 addToast,
    //                 res,
    //                 "Hiba történt a felhasználó lekérdezése közben..."
    //             )
    //         ) {
    //             return;
    //         }

    //         setAuthInfo((prevState: any) => ({
    //             ...prevState,
    //             loggedIn: login,
    //             token: token,
    //             userInfo: res.data.data,
    //         }));
    //     })
    //     .catch((err: any) => {
    //         handleApiError(
    //             err,
    //             "Hiba történt a felhasználó lekérdezése közben...",
    //             addToast
    //         );
    //     });
    setAuthInfo((prevState: any) => ({
      ...prevState,
      loggedIn: login,
      token: token,
      userInfo: {
        id: 1,
        name: 'Roli',
        email_verified_at: '2022-01-01 01:01',
      },
    }));
  };

  return (
    <IonApp>
      <AuthContext.Provider
        value={{
          authInfo: authInfo,
          setAuthInfo: setAuthInfo,
          setAuth: setAuth,
        }}
      >
        <GlobalErrors />
        <Routes setAuth={setAuth} />
      </AuthContext.Provider>
    </IonApp>
  );
};

export default App;
