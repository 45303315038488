/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PageWrapper from '../../layout/PageWrapper';
import { Redirect } from 'react-router';
import { useAuth } from '../../context/AuthContext';

const SettingsPage: React.FC = () => {
  const { authInfo } = useAuth();

  if (authInfo.loggedIn === false) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper title="Beállítások" id="page-settings" containerClasses="ion-padding">
      <h1>Beállítások</h1>
    </PageWrapper>
  );
};
export default SettingsPage;
