/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PageWrapper from '../../layout/PageWrapper';
import { ModelDeviceLog, ModelDeviceRelation } from '../../models/Device';
import { Redirect, useParams } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { getDeviceLogs } from '../../services/DeviceServices';
import MapHistory from '../../components/MapHistory';
import { useIonViewDidLeave, useIonViewDidEnter } from '@ionic/react';

interface Props {
  deviceRelations: ModelDeviceRelation[];
}

const DevicePage: React.FC<Props> = ({ deviceRelations }) => {
  const { authInfo } = useAuth();
  const { deviceId }: { deviceId: string } = useParams();
  const [logs, setLogs] = useState<{
    loading: boolean,
    logs: ModelDeviceLog[],
  }>({
    loading: true,
    logs: [],
  });

  const deviceRelation = deviceRelations.find(
    (deviceRelation: ModelDeviceRelation) => deviceRelation.id == parseInt(deviceId ?? 0)
  );

  useIonViewDidEnter(() => {
    getDeviceLogs(deviceId)
      .then((res: any) => {
        setLogs({
          loading: false,
          logs: res.data,
        });
      })
      .catch((err: any) => {
        console.error(err);
        setLogs({
          loading: false,
          logs: [],
        });
      });
  }, [deviceId]);

  useIonViewDidLeave(() => {
    setLogs({
      loading: true,
      logs: [],
    });
  });

  if (authInfo.loggedIn === false) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper title="Eszköz" id="page-device">
      <MapHistory loading={logs.loading} logs={logs.logs} deviceId={deviceId} />
    </PageWrapper>
  );
};
export default DevicePage;
