import React from 'react';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { personCircle } from 'ionicons/icons';

import './Header.scss';

const Header: React.FC = () => {
  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonTitle slot="start">NinjaTracker</IonTitle>
        <IonButtons slot="end">
          <IonButton>
            <IonIcon slot="icon-only" md={personCircle} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
