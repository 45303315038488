import React, { useEffect, useState } from 'react';
import { difference, isEqual } from 'lodash';
import { Redirect, Route } from 'react-router';
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonViewDidEnter,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useAuth } from '../context/AuthContext';
import MapPage from '../pages/protected/MapPage';
import ListPage from '../pages/protected/ListPage';
import { add, list, map, settings } from 'ionicons/icons';
import IntroPage from '../pages/IntroPage';
import { getDevices } from '../services/DeviceServices';
import { ModelDeviceRelation } from '../models/Device';
import NewDeviceModal from '../components/modals/NewDeviceModal';
import DevicePage from '../pages/protected/DevicePage';
import { Geolocation } from '@awesome-cordova-plugins/geolocation';
import { useAppDispatch } from '../store/hooks';
import { setGeolocation } from '../store/slices/systemSlice';
import SettingsPage from '../pages/protected/SettingsPage';
import AssignPage from '../pages/protected/AssignPage';
// import LostPasswordPage from "../pages/Auth/LostPasswordPage";
// import PasswordRecoveryPage from "../pages/Auth/PasswordRecoveryPage";
// import RegistrationPage from "../pages/Auth/RegistrationPage";
// import RegistrationVerifyPage from "../pages/Auth/RegistrationVerifyPage";

interface Props {
  setAuth: (login: boolean, token: string) => void;
}

const Routes: React.FC<Props> = ({ setAuth }) => {
  const { authInfo } = useAuth();
  const dispatch = useAppDispatch();

  const [deviceRelations, setDeviceRelations] = useState<ModelDeviceRelation[]>([]);
  const [showNewDeviceModal, setShowNewDeviceModal] = useState<boolean>(false);

  const fetchDeviceRelations = () => {
    if (authInfo.loggedIn) {
      getDevices()
        .then((res: any) => {
          if (!isEqual(res.data, deviceRelations)) {
            setDeviceRelations(res.data);
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const fetchGeolocation = () => {
    Geolocation.getCurrentPosition()
      .then((res: any) => {
        dispatch(
          setGeolocation({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          })
        );
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchGeolocation();
    fetchDeviceRelations();
    const interval = setInterval(() => {
      fetchGeolocation();
      fetchDeviceRelations();
    }, 5000);
    return () => clearInterval(interval);
  }, [authInfo]);

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet id="main">
          <Route exact path="/login">
            {authInfo?.loggedIn && authInfo?.userInfo?.email_verified_at ? (
              <Redirect to="/map" />
            ) : (
              <IntroPage onLogin={(login, token) => setAuth(login, token)} />
            )}
          </Route>
          {/* <Route exact path="/registration">
                    <RegistrationPage
                        onRegistration={(login, token) => setAuth(login, token)}
                    />
                </Route>
                <Route exact path="/lost-password">
                    <LostPasswordPage />
                </Route>
                <Route path="/password-recovery/:email">
                    <PasswordRecoveryPage
                        onLogin={(login, token) => setAuth(login, token)}
                    />
                </Route>
                <Route exact path="/verify">
                    <RegistrationVerifyPage />
                </Route> */}
          <Route exact path="/map">
            <MapPage deviceRelations={deviceRelations} />
          </Route>
          <Route exact path="/list">
            <ListPage deviceRelations={deviceRelations} />
          </Route>
          <Route exact path="/settings">
            <SettingsPage />
          </Route>
          <Route exact path="/device/:deviceId">
            <DevicePage deviceRelations={deviceRelations} />
          </Route>
          <Route exact path="/device-assign/:serial">
            <AssignPage onLogin={(login, token) => setAuth(login, token)} />
          </Route>
          <Route exact path="/">
            {authInfo?.loggedIn === false ? (
              <Redirect to="/login" />
            ) : authInfo?.loggedIn && authInfo?.userInfo && authInfo.userInfo.email_verified_at !== null ? (
              <Redirect to="/verify" />
            ) : (
              <Redirect to="/map" />
            )}
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="map" href="/map">
            <IonIcon icon={map} />
          </IonTabButton>
          <IonTabButton tab="list" href="/list">
            <IonIcon icon={list} />
          </IonTabButton>
          <IonTabButton tab="settings" href="/settings">
            <IonIcon icon={settings} />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      {/* {authInfo?.loggedIn && (
        <>
          <IonFab
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            onClick={() => setShowNewDeviceModal(true)}
            className="addNewDeviceFab"
          >
            <IonFabButton color="primary">
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
          <NewDeviceModal
            show={showNewDeviceModal}
            handleClose={(refresh: boolean) => {
              if (refresh) {
                fetchDeviceRelations();
              }
              setShowNewDeviceModal(false);
            }}
          />
        </>
      )} */}
    </IonReactRouter>
  );
};

export default Routes;
