import React from 'react';
import { IonText } from '@ionic/react';

export const isObject = (data: object) => {
  return !!data && data.constructor === Object;
};

export const forEachApiErrors = (err: any, setState: any) => {
  Object.keys(err).forEach((key) => {
    setState((prevState: any) => ({
      ...prevState,
      error_list: {
        ...prevState.error_list,
        [key]: err[key],
      },
    }));
  });
};

export const addApiErrors = (err: any, setState: any) => {
  if (isObject(err)) {
    forEachApiErrors(err, setState);
  } else {
    setState((prevState: any) => ({
      ...prevState,
      error_list: {
        ...prevState.error_list,
        error: err,
      },
    }));
  }
};

export const handleApiResponseError = (
  addToast: any,
  res: any,
  errText: string,
  setStatus: any = null,
  duration: number = 8000
): boolean => {
  if (res.data === undefined || res.data.error === true) {
    const message = res && res.data ? res.data.message : null;
    addToast({
      message: setStatus ? (typeof message === 'string' ? message : errText) : getErrorsArray(message).join('<br>'),
      duration: duration,
      color: 'danger',
      position: 'top',
    });
    if (setStatus) {
      addApiErrors(message ?? errText, setStatus);
    }
    return true;
  }
  return false;
};

export const handleApiError = (
  err: any,
  errText: string,
  addToast: any,
  setStatus: any = null,
  duration: number = 8000
) => {
  const errors = err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors : null;
  const message =
    err && err.response && err.response.data && err.response.data.message ? err.response.data.message : null;

  if (!err.response) {
    addToast({
      message: 'Nincs internetkapcsolat',
      duration: 4000,
      color: 'danger',
      position: 'top',
    });
    return;
  }

  let errorsString = '';
  if (errors !== null && typeof errors === 'object') {
    Object.entries(errors).forEach(([key, value]: any) => (errorsString += value + '<br/>'));
    addToast({
      message: errorsString,
      duration: duration,
      color: 'danger',
      position: 'top',
    });
  } else {
    addToast({
      message: setStatus ? (typeof message === 'string' ? message : errText) : getErrorsArray(message).join('<br>'),
      duration: duration,
      color: 'danger',
      position: 'top',
    });
  }

  if (setStatus) {
    addApiErrors(message ?? errText, setStatus);
  }
};

const getErrorsArray = (message: any): any => {
  let errorsArray: Array<string> = [];
  if (message) {
    if (typeof message === 'object') {
      // eslint-disable-next-line array-callback-return
      Object.values(message).map((errors: any) => {
        for (let i = 0; i < errors.length; i++) {
          errorsArray.push(errors[i]);
        }
      });
    } else {
      errorsArray.push(message);
    }
  }
  return errorsArray;
};

export const setInitError = (init_state: any, setState: any) => {
  setState((prevState: any) => ({
    ...prevState,
    error_list: init_state,
  }));
};

export const hasError = (err: any) => {
  if (err === undefined) return false;
  return Object.keys(err).length > 0;
};

export const hasErrorClass = (err: any) => {
  return hasError(err) ? 'hasError' : '';
};

export const ErrorsList: React.FC<{ errors: any }> = ({ errors }) => {
  return (
    <div className="errorsList">
      {Object.entries(errors).map(([key, value], index) => {
        if (value) {
          return (
            <p className="ion-text-center" data-testid="errorText" key={index}>
              <IonText color="danger">
                <>{value ?? ''}</>
              </IonText>
            </p>
          );
        } else {
          return <div key={index}></div>;
        }
      })}
    </div>
  );
};

export const GlobalSlideOptions = {
  initialSlide: 0,
  slidesPerView: 1,
  speed: 150,
  allowTouchMove: false,
};

export const secondsToMMSS = (seconds: number): string => {
  const MM = Math.floor(seconds / 60);
  const SS = Math.floor(seconds % 60);
  return `${MM < 10 ? '0' + MM : MM}:${SS < 10 ? '0' + SS : SS}`;
};

export const secondsToHHMMSS = (seconds: number): string => {
  const HH = Math.floor(seconds / 3600);
  const MM = Math.floor((seconds % 3600) / 60);
  const SS = Math.floor((seconds % 3600) % 60);
  return `${HH < 10 ? '0' + HH : HH}:${MM < 10 ? '0' + MM : MM}:${SS < 10 ? '0' + SS : SS}`;
};

export function hideTabs() {
  const tabsEl = document.querySelector('ion-tab-bar');
  if (tabsEl) {
    tabsEl.hidden = true;
  }
}

export function showTabs() {
  const tabsEl = document.querySelector('ion-tab-bar');
  if (tabsEl) {
    tabsEl.hidden = false;
  }
}
