import { HttpClient } from '../helpers/HttpClient';

export const getDevices = async () => {
  return await HttpClient({
    url: 'devices',
    method: 'get',
  });
};

export const postDeviceRelation = async (serial: string, name: string, gender: string) => {
  return await HttpClient({
    url: 'device-relation',
    method: 'post',
    data: {
      serial,
      name,
      gender,
    },
  });
};

export const getDeviceLogs = async (device_id: string) => {
  return await HttpClient({
    url: 'device-logs',
    method: 'get',
    params: {
      device_id,
    },
  });
};
