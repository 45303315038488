import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import React from 'react';
import PageWrapper from '../layout/PageWrapper';

interface ErrorPorps {
  children: any;
}

interface ErrorState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorPorps, ErrorState> {
  constructor(props: ErrorPorps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <PageWrapper>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Váratlan hiba történt</IonCardTitle>
                <IonCardContent>
                  <IonButton color="light" expand="block" routerLink="/map">
                    Vissza a főoldalra
                  </IonButton>
                </IonCardContent>
              </IonCardHeader>
            </IonCard>
          </PageWrapper>
        </>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
